import { BaseResponse } from "../Data.js";

type SetSettingsRequest = {key: string, value: string};
type SetSettingsResponse = {status: string, message: string};


type GetSettingsRequest = {key: string};
interface GetSettingsResponse extends BaseResponse {value: string};

export class HttpTool {

    static token?: string;

    static async fetchSimple(url: string, data: any): Promise<any> {

        let headers: [string, string][] = [["content-type", "text/json"]];

        if(HttpTool.token != null){
            headers.push(["x-token-pm", HttpTool.token]);
        }

        try {
            let response = await fetch(url, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            })
    
             let obj: BaseResponse = await response.json()
    
            if(obj["token"] != null){
                this.token = obj["token"];
            }

            if(obj == null){
                alert("Fehler beim Übertragen der Daten.");             
            } else if(obj.status != "OK"){
                alert("Fehler beim Übertragen der Daten:\n" + obj.message);             
            }

            return obj;
        } catch (exception){
            return {
                status: "Error",
                message: "Es ist ein Fehler aufgetreten: " + exception
            }
        }

    }

    public static async getSettings(key: string): Promise<any> {
        
        let gsr: GetSettingsRequest = {key: key};

        let response: GetSettingsResponse = await this.fetchSimple("servlet/getSettings", gsr);

        if(response?.status == "OK") return JSON.parse(response.value);

        return null;

    }

    public static async setSettings(key: string, value: string): Promise<boolean> {

        let ssr: SetSettingsRequest = {key: key, value: value};

        let response = <BaseResponse> await (this.fetchSimple("servlet/setSettings", ssr));

        return response?.status == "OK";        
    }

    public static async removeAllAnmeldungen(): Promise<boolean> {

        let response = <BaseResponse> await (this.fetchSimple("servlet/removeAllXLAnmeldungen", {}));

        return response?.status == "OK";        
    }

    public static async removeAllBeitrittserklaerungen(): Promise<boolean> {

        let response = <BaseResponse> await (this.fetchSimple("servlet/removeAllXLBeitrittserklaerungen", {}));

        return response?.status == "OK";        
    }


}